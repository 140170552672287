.card{
    width: 100%;
    padding:20px;
    height: auto;
}
.title{
    font-size: 18px;
    font-weight: 600;
}
.formConteiner{
  padding-top: 30px;
}
