.root {
   font-family: 'Fira Sans', sans-serif; 
  
}

.btn {
  color: red;
}
.ag-header-cell-label {
  justify-content: center;
  margin-left: 20px;
}
.card-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.card-subtitle {
  font-size: 1.25rem;
  font-weight: 600;
}

.card .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
} 

.card.card-cascade h4 {
  margin-bottom: 0px;
} 

 .cascading-admin-card .admin-up {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -20px;
}  

.cascading-admin-card .admin-up .fa {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up .fa {
   padding: 1.7rem;  
  font-size: 2rem;
   color: #fff; 
   border-radius: 3px;
  text-align: left;  
   margin-right: 1rem; 
  
} 
 .cascading-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right;
}


.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem;
}
.classic-admin-card .card-body p {
  font-size: 13px;
  opacity: 0.7;
   margin-bottom: 0; 
} 
 /* .classic-admin-card .card-body h4 {
  margin-top: 10px;
} */
 @media (max-width: 1199.98px) {
  .card.cascading-admin-card {
    margin-top: 2.5rem;
  }
}

@media (max-width: 576px) {
  .card.cascading-admin-card {
    margin-top: 2.5rem;
  }
}   
